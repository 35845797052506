<template>
  <div class="report-records-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-4">
              <div class="col-md-6">
                <label class="field-label">Date Range</label>
                <date-range-picker
                  ref="picker"
                  v-model="filter.dateRange"
                  :opens="filter.opens"
                  :locale-data="{ firstDay: 1, format: 'mmm dd, yyyy' }"
                  :min-date="filter.minDate"
                  :max-date="filter.maxDate"
                  :single-date-picker="filter.singleDatePicker"
                  :time-picker="filter.timePicker"
                  :time-picker24-hour="filter.timePicker24Hour"
                  :show-week-numbers="filter.showWeekNumbers"
                  :show-dropdowns="filter.showDropdowns"
                  :auto-apply="filter.autoApply"
                  :always-show-calendars="filter.alwaysShowCalendars"
                  :linked-calendars="filter.linkedCalendars"
                  :ranges="filter.ranges"
                  @update="filterDateUpdateValues"
                >
                </date-range-picker>
              </div>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getRecords"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
            </div>
            <template v-if="!loading">
              <div class="table-responsive mb-0">
                <b-table
                  :items="records"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(total)="data">
                    $ {{ data.item.total }}
                  </template>
                  <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | moment('MM-DD-YYYY hh:mm:ss A') }}
                  </template>
                  <template v-slot:cell(paid)="data">
                    <feather
                      v-if="data.item.paid"
                      type="check"
                      class="icon-dual-success"
                    />
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-button
                      variant="primary"
                      size="sm"
                      title="Download Invoice"
                      :disabled="processing"
                      @click="download(data.item)"
                    >
                      <b-spinner v-if="processing" small />
                      <i v-else class="uil uil-download-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Page <b>#{{ datatable.queries.pageIndex }}</b>
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <button
                      class="ml-1 btn btn-primary btn-sm"
                      :disabled="datatable.queries.pageIndex <= 1 || loading"
                      @click="onPagePrev"
                    >
                      <i class="uil uil-angle-double-left"></i> Prev
                    </button>
                    <button
                      class="ml-1 btn btn-primary btn-sm"
                      :disabled="noData || loading"
                      @click="onPageNext"
                    >
                      Next <i class="uil uil-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment-timezone'
import axios from 'axios'

export default {
  components: { 
    DateRangePicker
  },
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: 'Invoice Id',  sortable: false },
          { key: 'total', label: 'Amount', sortable: false },
          { key: 'paid', label: 'Paid', sortable: false },
          { key: 'created_at', label: 'Created At', sortable: false },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          pageIndex: 1,
          business_id: null,
          start_at: null,
          end_at: null,
        },
      },
      records: [],
      firstId: null,
      lastId: null,
      noData: false,
      loading: false,
      processing: false,
      businesses: [],
      filter : {
        opens: 'right',
        minDate: null,
        maxDate: null,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        dateRange: {
          startDate: moment().subtract(30, 'days').toDate(),
          endDate: moment().toDate(),
        },
        linkedCalendars: true,
        ranges: {
          'Today': [moment().toDate(), moment().toDate()],
          'Last 7 Days': [moment().subtract(7, 'days').toDate(), moment().toDate()],
          'Last 30 Days': [moment().subtract(30, 'days').toDate(), moment().toDate()],
          'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
        },
        alwaysShowCalendars: true,
      }
    }
  },

  computed: {
    totalRecords() {
      return this.records && this.records.meta ? this.records.meta.total : 0
    }
  },

  mounted() {
    this.filterDateUpdateValues()
    this.getBusinessOptions()
  },

  methods: {
    download(item)
    {
      this.processing = true
      axios.get(`/reports/download-invoice/${item.id}`)
        .then(response => {
          let data = response.data
          if (data.success)
          {
            window.open(data.data.url, '_blank')
          }
          this.processing = false
        }).catch(e => {
          this.processing = false
        })
    },

    onPagePrev() {
      this.getRecords('prev');
    },

    onPageNext() {
      this.getRecords('next');
    },

    filterDateUpdateValues(range) {
      this.datatable.queries.start_at = moment(this.filter.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      this.datatable.queries.end_at = moment(this.filter.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      this.getRecords();
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getRecords()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getRecords()
    },

    onQueryChange(type = undefined) {
      this.$nextTick(() => {
        if (type === 'search')
        {
          this.datatable.queries.page = 1;
        }
        this.getRecords()
      });
    },

    getBusinessOptions() {
      this.$store.dispatch('business/getBusinessOptions')
        .then((businesses) => {
          this.businesses = [
            { name: 'All businesses', id: null },
            ...businesses.map(b => {
              return {
                name: b.name,
                id: b.id,
              }
            })
          ]
          
        })
        .catch(() => {
        })
    },

    getRecords(direction = undefined) {
      this.loading = true

      let objId = null;
      if (direction === 'next') {
        objId = this.lastId;
      } else if (direction === 'prev') {
        objId = this.firstId;
      } else {
        this.datatable.queries.pageIndex = 1
      }

      let param = {
        direction,
        obj_id: objId,
        ...this.datatable.queries,
      }

      this.records = [];

      this.$store.dispatch('report/getBillingHistory', param)
        .then((res) => {
          this.records = res.data
          if (direction === 'next')
            this.datatable.queries.pageIndex++;
          else if (direction === 'prev')
            this.datatable.queries.pageIndex--;
          if (this.records.length === 0)
          {
            this.firstId = this.lastId
            this.noData = true;
          } else {
            if (this.records.length < this.datatable.queries.per_page )
            {
              this.noData = true;
            } else {
              this.noData = false;
            }
            this.firstId = this.records[0]['id'];
            this.lastId = this.records[this.records.length - 1]['id'];
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
        })
    },
    refresh() {
      this.datatable.queries.business_id = null
      this.getRecords();
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  width: 150px;
  height: calc( 1.5em + 0.5rem + 2px );
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-width: 200px;
}
</style>